import React, { Suspense } from 'react'
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from 'react';

import DefaultLayout from './Layouts/DefaultLayout';

// import Homepage from './Pages/Homepage';
// import About from './Pages/About';
// import Services from './Pages/Services';
// import Faq from './Pages/Faq';
// import Contact from './Pages/Contact';
// import CaseStudy from './Pages/CaseStudy';
// import Case1 from './Pages/caseStudy/Case1';
// import Case2 from './Pages/caseStudy/Case2';
// import Case3 from './Pages/caseStudy/Case3';
// import Career from './Pages/Career';
// import Blog from './Pages/Blog';
// import BlogDetai from './Pages/BlogDetai';
// import Error from './Pages/Error';

const Homepage = React.lazy(() => import('./Pages/Homepage'));
const About = React.lazy(() => import('./Pages/About'));
const Services = React.lazy(() => import('./Pages/Services'));
const Faq = React.lazy(() => import('./Pages/Faq'));
const Contact = React.lazy(() => import('./Pages/Contact'));
const CaseStudy = React.lazy(() => import('./Pages/CaseStudy'));
const Case1 = React.lazy(() => import('./Pages/caseStudy/Case1'));
const Case2 = React.lazy(() => import('./Pages/caseStudy/Case2'));
const Case3 = React.lazy(() => import('./Pages/caseStudy/Case3'));
const Career = React.lazy(() => import('./Pages/Career'));
const Blog = React.lazy(() => import('./Pages/Blog'));
const BlogDetai = React.lazy(() => import('./Pages/BlogDetai'));
const Error = React.lazy(() => import('./Pages/Error'));

const App = () => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)

    // setTimeout(() => console.clear(), 1500);


    document.addEventListener("DOMContentLoaded", function () {
      var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
      var lazyVideosUp = [].slice.call(document.querySelectorAll(".lazy video"));

      if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
          entries.forEach(function (video) {
            if (video.isIntersecting) {
              for (var source in video.target.children) {
                var videoSource = video.target.children[source];
                if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                  videoSource.src = videoSource.dataset.src;
                }
              }

              video.target.load();
              video.target.classList.remove("lazy");
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });

        lazyVideos.forEach(function (lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
        lazyVideosUp.forEach(function (lazyVideo) {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    });
  }, [location])
  return (
    <Suspense>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="" element={<DefaultLayout />}>
          <Route index element={<Homepage />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="case-studies" element={<CaseStudy />} />
          <Route path="case-studies/detail/fluctuation" element={<Case1 />} />
          <Route path="case-studies/detail/like-a-pro" element={<Case2 />} />
          <Route path="case-studies/detail/interaction" element={<Case3 />} />
          <Route path="career" element={<Career />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/detail/:slug" element={<BlogDetai />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App
