import gsap, { ScrollTrigger } from "gsap/all";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

function hoverMoveCircle() {
  const wrapCircleGroup = document.querySelector(".wrapCircleGroup");
  wrapCircleGroup.addEventListener("mousemove", mouseMove);
  wrapCircleGroup.addEventListener("mouseleave", mouseBack);
}

function mouseMove(e) {
  // const depth = 24;
  // const moveX = (e.pageX - window.innerWidth / 2) / depth;
  // const moveY = (e.pageY - window.innerHeight / 2) / depth;
  // gsap.to(".circleHover", {
  //   x: moveX,
  //   y: moveY,
  //   ease: "slow",
  //   stagger: 0.008,
  //   overwrite: "all",
  //   overwrite: true,
  // });

  const wrapCircleGroup = document.querySelector(".wrapCircleGroup");
  const circleHover = gsap.utils.toArray(".circleHover");
  const depth = 10;
  const moveX = (e.pageX - window.innerWidth / 2) / depth;
  const moveY = (e.pageY - window.innerHeight / 2) / depth;

  gsap.to(".circleHover.one", {
    x: moveX,
    y: moveY + 30,
    ease: "slow",
    overwrite: true,
    duration: 0.5,
  });
  gsap.to(".circleHover.two", {
    x: moveX + 30,
    y: moveY + 20,
    ease: "slow",
    overwrite: true,
    duration: 0.8,
  });
  gsap.to(".circleHover.three", {
    x: moveX + 20,
    y: moveY - 30,
    ease: "slow",
    overwrite: true,
    duration: 1,
  });
}

function mouseBack(e) {
  const wrapCircleGroup = document.querySelector(".wrapCircleGroup");
  const circleHover = gsap.utils.toArray(".circleHover");
  const depth = 24;
  const moveX = (e.pageX - window.innerWidth / 2) / depth;
  const moveY = (e.pageY - window.innerHeight / 2) / depth;

  gsap.to(".circleHover.one", {
    x: 0,
    y: 0,
    ease: "slow",
    overwrite: true,
    duration: 0.5,
  });
  gsap.to(".circleHover.two", {
    x: 0,
    y: 0,
    ease: "slow",
    overwrite: true,
    duration: 0.8,
  });
  gsap.to(".circleHover.three", {
    x: 0,
    y: 0,
    ease: "slow",
    overwrite: true,
    duration: 1,
  });
}

function AnimCaseStudy() {
  const itemCase = gsap.utils.toArray(".pinCasestudy");
  const itemCaseNav = gsap.utils.toArray(".pinCasestudyNav");

  mm.add("(max-width: 767px)", () => {
    itemCaseNav.forEach((data) => {
      ScrollTrigger.create({
        trigger: ".wrapContentScaseStudy",

        pin: data,
        pinSpacing: false,
        start: "top +=95px",
        end: "+=58% +=10%",
      });
    });
  });
  mm.add(
    "(min-width: 768px) and (min-height:400px) and (max-height:698px)",
    () => {
      itemCase.forEach((items) => {
        ScrollTrigger.create({
          trigger: ".wrapContentScaseStudy",

          pin: items,
          pinSpacing: false,
          start: "top +=24%",
          end: "+=60% +=10%",
        });
      });
      itemCaseNav.forEach((data) => {
        ScrollTrigger.create({
          trigger: ".wrapContentScaseStudy",

          pin: data,
          pinSpacing: false,
          start: "top +=114px",
          // start: "top +=18%",
          end: "+=58% +=10%",
        });
      });
    }
  );

  mm.add(
    "(min-width: 768px) and (min-height:699px) and (max-height:1099px)",
    () => {
      itemCase.forEach((items) => {
        ScrollTrigger.create({
          trigger: ".wrapContentScaseStudy",

          pin: items,
          pinSpacing: false,
          start: "top +=24%",
          end: "+=50% +=10%",
        });
      });
      itemCaseNav.forEach((data) => {
        ScrollTrigger.create({
          trigger: ".wrapContentScaseStudy",

          pin: data,
          pinSpacing: false,
          start: "top +=114px",
          // start: "top +=12%",
          end: "+=58% +=10%",
        });
      });
    }
  );
  mm.add(
    "(min-width: 768px) and (min-height: 1100px) and (max-height: 1280px)",
    () => {
      itemCase.forEach((items) => {
        ScrollTrigger.create({
          trigger: ".wrapContentScaseStudy",

          pin: items,
          pinSpacing: false,
          start: "top +=24%",
          end: "+=20% +=10%",
        });
      });
      itemCaseNav.forEach((data) => {
        ScrollTrigger.create({
          trigger: ".wrapContentScaseStudy",

          pin: data,
          pinSpacing: false,
          start: "top +=114px",
          // start: "top +=10%",
          end: "+=58% +=10%",
        });
      });
    }
  );

  mm.add("(min-width: 768px) and (min-height: 1281px)", () => {
    itemCase.forEach((items) => {
      ScrollTrigger.create({
        trigger: ".wrapContentScaseStudy",

        pin: items,
        pinSpacing: false,
        start: "top +=24%",
        end: "+=5% +=10%",
      });
    });
    itemCaseNav.forEach((data) => {
      ScrollTrigger.create({
        trigger: ".wrapContentScaseStudy",

        pin: data,
        pinSpacing: false,
        start: "top +=114px",
        end: "+=58% +=10%",
      });
    });
  });
}

function AnimHero() {
  let animLine = gsap.timeline();

  const ourText = SplitType.create(".textHero", { types: "lines" });
  const textToLeft = SplitType.create(".toleft", { types: "lines" });
  gsap.set(".wrapCircleGroup", {
    height: 0,
    autoAlpha: 0,
  });
  gsap.set(".circleHover.one", {
    x: 100,
    y: -100,
    overflow: 0,
  });
  gsap.set(".circleHover.two", {
    x: -100,
    y: 0,
    overflow: 0,
  });
  gsap.set(".circleHover.three", {
    x: 100,
    y: 100,
    overflow: 0,
  });

  animLine.add([
    gsap.fromTo(
      ourText.lines,
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      textToLeft.lines,
      {
        x: -100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".circleHero",
      {
        scale: 0,
        x: 0,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        scale: 1,
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toTop",
      {
        x: 0,
        y: 200,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".zoomHeroContent",
      {
        scale: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        scale: 1,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
  ]);

  animLine.duration(2).play();

  animLine.add([
    gsap.to(".wrapCircleGroup", {
      height: "100%",
      autoAlpha: 1,
      duration: 0.5,
    }),
    gsap.to(".circleHover.one", {
      x: 0,
      y: 0,
      overflow: 1,
      duration: 0.5,
    }),
    gsap.to(".circleHover.two", {
      x: 0,
      y: 0,
      overflow: 1,
      duration: 0.5,
    }),
    gsap.to(".circleHover.three", {
      x: 0,
      y: 0,
      overflow: 1,
      duration: 0.5,
    }),
  ]);
}

function AnimCircle() {
  let animLine = gsap.timeline({
    reversed: true,
  });

  animLine.add([
    gsap.fromTo(
      ".rotateCircle",
      {
        rotate: 180,
        force3D: true,
        x: 400,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        rotate: 0,
        force3D: true,
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
  ]);

  ScrollTrigger.create({
    trigger: ".sectionCircleRotate",
    start: "top 40%",
    end: "bottom center",
    onEnter: () => {
      animLine.duration(1.5).play();
    },
    onLeaveBack: () => {
      animLine.duration(1.5).reverse();
    },
  });
}

function AnimAbout() {
  let animLine = gsap.timeline({
    reversed: true,
  });

  animLine.add([
    gsap.fromTo(
      ".toRIghtItem",
      {
        x: -100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toTopAbout",
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toleftAbout",
      {
        x: 100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
  ]);

  ScrollTrigger.create({
    trigger: ".sectionAbout",
    start: "top 40%",
    end: "bottom center",
    onEnter: () => {
      animLine.duration(1).play();
    },
    onLeaveBack: () => {
      animLine.duration(1).reverse();
    },
  });
}

function AnimCase() {
  let animLine = gsap.timeline({
    reversed: true,
  });

  animLine.set(".wrapContentScaseStudy", {
    x: 0,
    y: "100vh",
    autoAlpha: 0,
  });

  animLine.add([
    gsap.fromTo(
      ".toRightCase",
      {
        x: -100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toTopCase",
      {
        x: 0,
        y: -100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.to(".wrapContentScaseStudy", {
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "back",
    }),
  ]);

  ScrollTrigger.create({
    trigger: ".sectionCase",
    start: "top 40%",
    end: "bottom center",
    onEnter: () => {
      animLine.duration(1.5).play();
    },
    onLeaveBack: () => {
      animLine.duration(1.5).reverse();
    },
  });
}

function AnimValue() {
  const ourText = SplitType.create(".textValue", { types: "lines" });

  let animLine = gsap.timeline({
    reversed: true,
  });

  animLine.add([
    gsap.fromTo(
      ourText.lines,
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toUpFaq",
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
  ]);

  ScrollTrigger.create({
    trigger: ".sectionValue",
    start: "top 40%",
    end: "bottom center",
    onEnter: () => {
      animLine.duration(1.5).play();
    },
    onLeaveBack: () => {
      animLine.duration(1.5).reverse();
    },
  });
}

function AnimContactTeam() {
  const ourText = SplitType.create(".textContactTeam", { types: "lines" });

  let animLine = gsap.timeline({
    reversed: true,
  });

  animLine.add([
    gsap.fromTo(
      ourText.lines,
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toUpCOntactTeam",
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".zoomCOntactTeam",
      {
        scale: 0,
        x: 0,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        scale: 1,
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
  ]);

  mm.add("(max-height: 900px)", () => {
    ScrollTrigger.create({
      trigger: ".sectionContactTeam",
      start: "top 40%",
      end: "bottom center",
      onEnter: () => {
        animLine.duration(1.5).play();
      },
      onLeaveBack: () => {
        animLine.duration(1.5).reverse();
      },
    });
  });

  mm.add("(min-height: 901px)", () => {
    ScrollTrigger.create({
      trigger: ".sectionContactTeam",
      start: "top bottom",
      end: "bottom bottom",
      onEnter: () => {
        animLine.duration(1.5).play();
      },
      onLeaveBack: () => {
        animLine.duration(1.5).reverse();
      },
    });
  });
}

function AnimFooter() {
  let animLine = gsap.timeline({
    reversed: true,
  });

  animLine.add([
    gsap.fromTo(
      ".toRightFooter",
      {
        x: -100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toUpfooter",
      {
        x: 0,
        y: 100,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toRIightSosmed",
      {
        x: -100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
    gsap.fromTo(
      ".toLeftMail",
      {
        x: 100,
        y: 0,
        opacity: 0,
        overwrite: "all",
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        stagger: 0.1,
        overwrite: "all",
      }
    ),
  ]);

  ScrollTrigger.create({
    trigger: ".sectionFooter",
    start: "top bottom",
    end: "-=20% top",
    onEnter: () => {
      animLine.duration(1.5).play();
    },
    onLeaveBack: () => {
      animLine.duration(1.5).reverse();
    },
  });
}

export default {
  hoverMoveCircle,
  AnimCaseStudy,
  AnimHero,
  AnimCircle,
  AnimAbout,
  AnimCase,
  AnimValue,
  AnimContactTeam,
  AnimFooter,
};
