import React, { Fragment, useLayoutEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import AnimHome from '../Animations/AnimHome'
import gsap from 'gsap'

const Footer = () => {
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            AnimHome.AnimFooter()
        },);
        return () => ctx.revert();
    }, [])
    return (
        <Fragment>
            <section className='border-t border-solid !border-[#27272A] pt-[40px] pb-[20px] overflow-hidden sectionFooter'>
                <Container>
                    <div className="relative mb-4 text-center md:!text-left">
                        <NavLink reloadDocument preventScrollReset={true} to="/" className="inline-block toRightFooter">
                            <img src="./../images/Logo.svg" alt="" />
                        </NavLink>

                        <div className="md:absolute md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 w-max text-center mx-auto mt-4 md:!my-0">
                            <h5 className='font-GilroyBold text__20 mb-3 toUpfooter'>Useful links</h5>

                            <div className="flex items-center justify-center gap-4 toUpfooter">
                                <NavLink reloadDocument preventScrollReset={true} to="/" className='font-GilroyMedium text__16 text-white'>Home</NavLink>
                                <NavLink reloadDocument preventScrollReset={true} to="/about" className='font-GilroyMedium text__16 text-white'>About Us</NavLink>
                                <NavLink reloadDocument preventScrollReset={true} to="/services" className='font-GilroyMedium text__16 text-white'>Services</NavLink>
                                <NavLink reloadDocument preventScrollReset={true} to="/faq" className='font-GilroyMedium text__16 text-white'>FAQ</NavLink>
                                <NavLink reloadDocument preventScrollReset={true} to="/blog" className='font-GilroyMedium text__16 text-white'>Blog</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="relative flex items-center flex-wrap justify-center gap-3 md:gap-0 md:justify-between">
                        <div className="flex items-center gap-3 md:w-auto w-full justify-center md:justify-start toRIightSosmed">
                            <a href="#!" target='_blank'><img src={window.origin + "/images/ss (1).png"} alt="" /></a>
                            {/* <a href="#!" target='_blank'><img src="./../images/ss (2).png" alt="" /></a> */}
                        </div>
                        <a href="mailto:hello@vendoragency.com" className='text-white font-GilroyMedium text__16 toLeftMail'>hello@vendoragency.com</a>
                        <div className="md:absolute md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 text-[#3F3F46] font-GilroyMedium text__16 md:w-auto w-full text-center mx-auto">
                            <span className='toUpfooter'>
                                © VENDORA Agency. All Rights Reserved 2023.
                            </span>
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default Footer
