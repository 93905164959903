import React from 'react'
import { Container, Form, InputGroup } from 'react-bootstrap'
import { Fragment } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { CartIcon, CloseIcon, LogoIcon, SearchIcon } from '../Icon/Icon'
import { BtnStarted } from '../Button/Button'

const Navbar = () => {

    let location = useLocation();
    const [ToogleMenuResponsive, setToogleMenuResponsive] = useState(false);
    const [navabarScroll, setnavabarScroll] = useState(false)

    const stickNavabr = () => {
        if (window.scrollY > 100) {
            setnavabarScroll(true)
        } else {
            setnavabarScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', stickNavabr);
    }, [location])

    return (
        <Fragment>

            {/* <div className="fixed z-[9999] w-full h-full left-0 top-0 flex items-center justify-center">
                <div className="relative w-full h-full">
                    <div className="bg-Mblack opacity-6 absolute w-full h-full left-0 top-0"></div>

                    <div className="p-4 rounded-xl">

                    </div>
                </div>
            </div> */}

            <div onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className={"fixed w-full h-full bg-[#151518CC] left-0 z-[80] " + (ToogleMenuResponsive ? "top-0" : "-top-[100vh]")}></div>
            <div className={"fixed h-auto w-full bg-Mblack z-[99] pt-[100px] sm:pt-[120px] pb-[20px] menuMobile " + (ToogleMenuResponsive ? "active" : "")}>
                <div className="relative w-full h-full">
                    <div className="absolute left-0 top-0 bg-Mblack w-full h-full"></div>
                    <Container className='h-full w-full relative z-[1]'>
                        <ul className='list-none p-0 m-0 flex items-center flex-wrap gap-4 text__16 w-full'><li className='w-full'>
                            <NavLink reloadDocument preventScrollReset={true} to="/" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>Home</NavLink>
                        </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/about" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>About Us</NavLink>
                            </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/services" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>Services</NavLink>
                            </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/faq" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>FAQ</NavLink>
                            </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/contact" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>Contact</NavLink>
                            </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/case-studies" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>Case Studies</NavLink>
                            </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/career" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>Career</NavLink>
                            </li>
                            <li className='w-full'>
                                <NavLink reloadDocument preventScrollReset={true} to="/blog" onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className='font-medium font-Inter text-white'>Blog</NavLink>
                            </li>

                        </ul>
                    </Container>
                </div>
            </div>

            <div className={'fixed bg-Mblack py-4 w-full z-[999] left-0 top-0 border-b border-solid !border-[#27272A] '}>
                <Container className='relative flex items-center'>


                    <NavLink reloadDocument preventScrollReset={true} to="/" className=''>
                        <img src={window.origin + "/images/Logo.svg"} className='sm:w-auto w-[120px]' alt="" />
                    </NavLink>


                    <ul className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 list-none p-0 m-0 items-center text__16 w-max hidden xl:flex'>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>Home</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/about" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>About Us</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/services" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>Services</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/faq" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>FAQ</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/contact" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>Contact</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/case-studies" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>Case Studies</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/career" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>Career</NavLink>
                        </li>
                        <li>
                            <NavLink reloadDocument preventScrollReset={true} to="/blog" className='font-GilroySemibold px-[1.5rem] py-[0.6em] rounded-full text__16 hover:bg-white hover:text-Mblack navMenu transition-all duration-500'>Blog</NavLink>
                        </li>
                    </ul>


                    <div className="flex items-center ml-auto gap-3">
                        <NavLink reloadDocument preventScrollReset={true} to="/contact" className={'hidden xl:inline-block cursor-pointer font-GilroySemibold text__16 rounded-full borderOrageGradient hover:bg-[linear-gradient(64.41deg,#FF7347_0.15%,#FF5D2A_23.97%,#FF8947_57.65%,#FF7347_95.48%),linear-gradient(242.22deg,#FF5D2A_22.43%,rgba(255,93,42,0)67.25%)] hover:text-white leading-6 px-[2rem] py-[1rem] border-Mblack !text-white'}>APPLY NOW</NavLink>

                        <div onClick={() => setToogleMenuResponsive(!ToogleMenuResponsive)} className={"relative px-1 py-1 barIcon w-[30px] h-[30px] cursor-pointer xl:hidden ml-auto " + (ToogleMenuResponsive ? "active" : "")}>
                            <div className="bg-white"></div>
                            <div className="bg-white"></div>
                            <div className="bg-white"></div>
                        </div>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default Navbar
