import React, { Fragment, useLayoutEffect } from 'react'
import { Outlet } from "react-router-dom"
import Navbar from '../Components/Nav/Navbar'
import Footer from '../Components/Nav/Footer'
import gsap from 'gsap'
import prefix from '../Components/Animations/prefix'

const DefaultLayout = () => {
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            prefix.ignoreResize()
        },);
        return () => ctx.revert();
    }, [])
    return (
        <Fragment>
            <div className='w-full overflow-hidden'>
                <Navbar />

                <div className="mt-[88px] lg:mt-[98px] ">
                    <Outlet />
                </div>

                <Footer />
            </div>
        </Fragment>
    )
}

export default DefaultLayout
