import React from 'react'

export const LogoIcon = ({ width = 40, height = 40, color = "#FEC51C" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.6673 23.3334L30.3073 32.1735C30.0571 33.7997 28.658 35 27.0127 35H12.9886C11.3433 35 9.94414 33.7997 9.69398 32.1735L8.33398 23.3334" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.666 15.7237L25.8327 16.557" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.3327 15.7237L14.166 16.557" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.5 11.557L16.6667 12.3904" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.5007 11.557L23.334 12.3904" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.5827 16.557L20.416 17.3904" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.7477 16.6667C31.2513 10.5469 26.1399 5.83325 20 5.83325C13.8601 5.83325 8.74867 10.5469 8.25227 16.6667C6.45609 16.6667 5 18.1228 5 19.9189V20.0811C5.00001 21.8772 6.45609 23.3333 8.25227 23.3333H31.7477C33.5439 23.3333 35 21.8772 35 20.0811V19.9189C35 18.1228 33.5439 16.6667 31.7477 16.6667Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const SearchIcon = ({ width = 24, height = 24, color = "#171717" }) => {
    return (
        <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.739 2C17.109 2 21.477 6.368 21.477 11.738C21.477 14.2715 20.5047 16.5823 18.9136 18.3165L22.0444 21.4407C22.3374 21.7337 22.3384 22.2077 22.0454 22.5007C21.8994 22.6487 21.7064 22.7217 21.5144 22.7217C21.3234 22.7217 21.1314 22.6487 20.9844 22.5027L17.8159 19.343C16.1491 20.6778 14.0357 21.477 11.739 21.477C6.369 21.477 2 17.108 2 11.738C2 6.368 6.369 2 11.739 2ZM11.739 3.5C7.196 3.5 3.5 7.195 3.5 11.738C3.5 16.281 7.196 19.977 11.739 19.977C16.281 19.977 19.977 16.281 19.977 11.738C19.977 7.195 16.281 3.5 11.739 3.5Z" fill={color} />
        </svg>
    )
}
export const CloseIcon = ({ width = 24, height = 24, color = "#292D32" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L18.9991 18.9991" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.00094 18.9991L19 5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
export const PlayIcon = ({ width = 24, height = 24, color = "white" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9997 2.3335C7.55967 2.3335 2.33301 7.56016 2.33301 14.0002C2.33301 20.4402 7.55967 25.6668 13.9997 25.6668C20.4397 25.6668 25.6663 20.4402 25.6663 14.0002C25.6663 7.56016 20.4397 2.3335 13.9997 2.3335ZM17.103 16.0185L15.6097 16.8818L14.1163 17.7452C12.1913 18.8535 10.6163 17.9435 10.6163 15.7268V14.0002V12.2735C10.6163 10.0452 12.1913 9.14683 14.1163 10.2552L15.6097 11.1185L17.103 11.9818C19.028 13.0902 19.028 14.9102 17.103 16.0185Z" fill={color} />
        </svg>
    )
}
export const LinkOpenIcon = ({ width = 28, height = 24, color = "#292D32" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.08366 26.5415H23.917C24.3953 26.5415 24.792 26.1448 24.792 25.6665C24.792 25.1882 24.3953 24.7915 23.917 24.7915H4.08366C3.60532 24.7915 3.20866 25.1882 3.20866 25.6665C3.20866 26.1448 3.60532 26.5415 4.08366 26.5415Z" fill={color} />
            <path d="M5.83365 21.2918C6.05531 21.2918 6.27698 21.2102 6.45198 21.0352L22.7853 4.70184C23.1236 4.36351 23.1236 3.80351 22.7853 3.46518C22.447 3.12684 21.887 3.12684 21.5486 3.46518L5.21532 19.7985C4.87698 20.1368 4.87698 20.6968 5.21532 21.0352C5.39032 21.2102 5.61198 21.2918 5.83365 21.2918Z" fill={color} />
            <path d="M22.167 16.9402C22.6453 16.9402 23.042 16.5435 23.042 16.0652V4.0835C23.042 3.60516 22.6453 3.2085 22.167 3.2085H10.1853C9.70699 3.2085 9.31032 3.60516 9.31032 4.0835C9.31032 4.56183 9.70699 4.9585 10.1853 4.9585H21.292V16.0652C21.292 16.5435 21.6887 16.9402 22.167 16.9402Z" fill={color} />
        </svg>

    )
}